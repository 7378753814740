<template>
  <div class="pay-success">
    <div class="pay-success--header">
      <van-icon name="checked" class="pay-success--icon"/>
      <span>存入预付款成功</span>
    </div>

    <div class="pay-success--body">
       <van-cell-group>
          <van-cell title="户号：" :value="yfkxx.yhbh"/>
          <van-cell title="预存金额：" :value="yfkxx.cryfk | currency"/>
          <!-- <van-cell title="预存时间" :value="0"/> -->
        </van-cell-group>
    </div>

    <div class="pay-success--footer">
      <van-button plain hairline type="primary" @click="pageBack">返回预付款存入</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YfkcrSuccess',
  data() {
    return {
      yfkxx: {}
    }
  },
  methods: {
    pageBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.yfkxx = this.$route.query;
  },
}
</script>

<style lang="scss" scoped>
  @import '@/styles/pay-success.scss';
</style>